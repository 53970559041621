<template>
  <div class="d-flex align-center flex-column">
    <v-card>
      <s-toolbar
        label="Impresion de Guía de cosecha"
        dark
        :color="'#BAB6B5'"
        close
        @close="$emit('close')"
        class="mb-6"
      ></s-toolbar>
      <v-card-title style="font-size: small">
        <v-row>
          <!-- <v-col>
						{{ "EVOLUCIÓN DE ETAPA FENOLOGICA" }}	
					</v-col> -->
          <!-- @click="print()" -->
          <v-col align="right">
            <v-btn fab x-small color="info" @click="downloadPdf()">
              <v-icon medium>mdi-printer</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="false"
        :filename="'Guia-' + this.referralGuideSelected.FltCodSenasa"
        :paginate-elements-by-height="1200"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :manual-pagination="true"
        :margin="1"
        @progress="onProgress($event)"
        @startPagination="startPagination()"
        @hasPaginated="hasPaginated()"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <v-container
            style="margin: 0; padding: 20px"
            class="bg-surface-variant"
          >
            <!-- seccion de encabezado de guia de cosecha -->
            <v-row>
              <v-col cols="4">
                <img
                  width="190px"
                  src="../../../../public/static/logo_sp.png"
                />
              </v-col>
              <v-col cols="4">
                <h1 style="top: 0; font-size: 23px; font-weight: bold">
                  GUÍA DE COSECHA
                </h1>
              </v-col>
              <v-col cols="auto">
                <v-row>
                  <v-col cols="auto" class="text-right">
                    <p
                      style="
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 10px;
                        font-weight: bolder;
                      "
                    >
                      <strong>Codigo</strong> <br />
                      <strong>Versión</strong> <br />
                      <strong>Fecha de Creación</strong> <br />
                      <strong>Última revisión</strong>
                    </p>
                  </v-col>
                  <v-col cols="auto">
                    <p style="font-size: 10px">
                      AF-FR-051 <br />
                      04 <br />
                      25/09/2016 <br />
                      16/10/2023
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- seccion de encabezado de guia de cosecha -->
            <!-- seccion de datos de la guia de cosecha -->
            <v-row
              style="
                margin: 0;
                padding: 0;
                font-size: 12px;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
              "
            >
              <!-- Guía de Producción -->
              <v-col cols="max" style="margin: 0; padding: 0">
                <!-- Datos de Cosecha -->
                <v-row style="padding: 0; margin: 0">
                  <v-row style="margin-top: 0; padding: 0 8px 0 8px">
                    <v-col cols="max" style="padding: 0 4px">
                      <!-- lugar de produccion -->
                      <v-col cols="auto" style="margin-top: 5px; padding: 0">
                        <v-row style="margin: 0">
                          <v-col
                            cols="auto"
                            style="padding: 0; margin-right: 5px"
                            >Código de lugar de producción:
                          </v-col>
                          <v-col cols="6">
                            <v-row>
                              <v-col
                                cols="max"
                                v-for="(item, index) in formattedProductionCode"
                                :key="index"
                                style="
                                  padding: 2px;
                                  margin: 0;
                                  margin-right: 2px;
                                  font-size: 10px;
                                "
                                :style="
                                  item.value !== '-'
                                    ? 'border: 2px solid black; text-align: center;margin:0;'
                                    : 'text-align: center;margin:0;'
                                "
                              >
                                {{ item.value }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Fundo -->
                      <v-col cols="auto">
                        <v-row>
                          <v-col cols="auto" style="padding: 0"> Fundo: </v-col>
                          <v-col
                            cols="max"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 0;
                              height: 17px;
                              text-align: center;
                            "
                          >
                            {{ this.referralGuideSelected.FagName }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 2px;
                              height: 17px;
                              text-align: center;
                            "
                          >
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Direccion -->
                      <v-col cols="auto">
                        <v-row>
                          <v-col cols="auto" style="padding: 0">
                            Dirección:
                          </v-col>
                          <v-col
                            cols="max"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 0;
                              height: 17px;
                              text-align: center;
                            "
                          >
                            {{ this.referralGuideSelected.FagAddres }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 2px;
                              height: 17px;
                              text-align: center;
                            "
                          >
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Productor -->
                      <v-col cols="auto">
                        <v-row>
                          <v-col cols="auto" style="padding: 0">
                            Productor:
                          </v-col>
                          <v-col
                            cols="max"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 0;
                              height: 17px;
                              text-align: center;
                            "
                          >
                            {{ this.referralGuideSelected.PrdCardName }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 2px;
                              height: 17px;
                              text-align: center;
                            "
                          >
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Parcela -->
                      <v-col cols="auto">
                        <v-row>
                          <v-col cols="auto" style="padding: 0">
                            Parcela:
                          </v-col>
                          <v-col
                            cols="max"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 0;
                              height: 17px;
                              text-align: center;
                            "
                          >
                            {{ this.referralGuideSelected.FltParcelName }}
                          </v-col>
                          <v-col
                            cols="12"
                            style="
                              border-bottom: 1px solid black;
                              padding: 0;
                              margin: 2px;
                              height: 17px;
                              text-align: center;
                            "
                          >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-row>

                <v-row style="padding: 8px">
                  <!-- Campo: ID -->
                  <v-col cols="2" style="margin: 10px">
                    <!-- Placa -->
                    <v-row>
                      <v-col cols="auto" style="padding: 0; margin-right: 5px"
                        >ID:
                      </v-col>
                      <v-col cols="max">
                        <v-row>
                          <v-col
                            cols="max"
                            v-for="(item, index) in formattedIdCode"
                            :key="index"
                            style="
                              padding: 2px;
                              margin: 0;
                              margin-right: 2px;
                              font-size: 10px;
                            "
                            :style="
                              item.value !== '-'
                                ? 'border: 2px solid black; text-align: center;margin:0;'
                                : 'text-align: center;margin:0;'
                            "
                          >
                            {{ item.value }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Campo: Tipo de Cultivo -->
                  <v-col cols="4">
                    <v-row style="padding: 4px">
                      <v-col
                        cols="4"
                        style="margin: 0; padding: 0; text-align: center"
                      >
                        Tipo de Cultivo:
                      </v-col>
                      <v-col cols="max" style="padding: 0">
                        <v-row style="margin: 0 5px 0 5px; padding: 0">
                          <v-col
                            v-for="(item, index) in typeCrops"
                            :key="index"
                            style="
                              padding: 3px;
                              margin: 2px;
                              border: 1px solid black;
                              border-radius: 5px;
                              font-size: 9px;
                            "
                            :style="
                              item.DedDescription ==
                              referralGuideSelected.TypeCropName
                                ? 'background-color: #f3f3f3'
                                : ''
                            "
                          >
                            <v-col
                              cols="auto"
                              style="
                                padding: 0;
                                margin: auto;
                                text-align: center;
                              "
                            >
                              {{ item.DedDescription }}
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row style="margin:0;padding-bottom: 8px;padding-top: 8px">
                      <v-col
                        cols="auto"
                        style="margin: 0; padding: 0; text-align: center"
                      >
                        Código:
                      </v-col>
                      <v-col style="padding: 0">
                        <v-col
                          cols="/"
                          style="
                            border-bottom: 1px solid black;
                            padding: 0;
                            margin: 0px;
                            height: 17px;
                            text-align: center;
                          "
                        >
                          {{ this.referralGuideSelected.FltCode }}
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <!-- Campo: Certificaciones -->
                    <v-row style="padding: 4px">
                      <v-col style="margin:0;padding:0">
                        <!-- Certificación Global gap -->
                        <v-row style="margin:0; padding: 4px">
                          <v-col
                            cols="max"
                            style="margin: auto 0 auto 0; padding: 0"
                          >
                            Certificación GLOBAL G.A.P:
                          </v-col>
                          <v-col cols="3" style="padding: 0">
                            <v-row style="margin: 0 auto 0 auto; padding: 0">
                              <v-row
                                style="
                                  padding: 3px;
                                  margin: 2px;
                                  border: 1px solid black;
                                  border-radius: 5px;
                                  font-size: 10px;
                                "
                                :style="
                                  referralGuideSelected.getCertGlobal
                                    ? 'background-color: #f3f3f3'
                                    : ''
                                "
                              >
                                <v-col
                                  cols="auto"
                                  style="
                                    padding: 0;
                                    margin: auto;
                                    text-align: center;
                                  "
                                >
                                  SI
                                </v-col>
                              </v-row>
                              <v-row
                                style="
                                  padding: 3px;
                                  margin: 2px;
                                  border: 1px solid black;
                                  border-radius: 5px;
                                  font-size: 10px;
                                "
                                :style="
                                  !referralGuideSelected.getCertGlobal
                                    ? 'background-color: #f3f3f3'
                                    : ''
                                "
                              >
                                <v-col
                                  cols="auto"
                                  style="
                                    padding: 0;
                                    margin: auto;
                                    text-align: center;
                                  "
                                >
                                  NO
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- Certificación Rainforest: -->
                        <v-row style="margin:0;padding: 4px">
                          <v-col
                            cols="max"
                            style="margin: auto 0 auto 0; padding: 0"
                          >
                            Certificación Rainforest:
                          </v-col>
                          <v-col cols="3" style="padding: 0">
                            <v-row style="margin: 0 auto 0 auto; padding: 0">
                              <v-row
                                style="
                                  padding: 3px;
                                  margin: 2px;
                                  border: 1px solid black;
                                  border-radius: 5px;
                                  font-size: 10px;
                                "
                                :style="
                                  referralGuideSelected.getCertRainForest
                                    ? 'background-color: #f3f3f3'
                                    : ''
                                "
                              >
                                <v-col
                                  cols="auto"
                                  style="
                                    padding: 0;
                                    margin: auto;
                                    text-align: center;
                                  "
                                >
                                  SI
                                </v-col>
                              </v-row>
                              <v-row
                                style="
                                  padding: 3px;
                                  margin: 2px;
                                  border: 1px solid black;
                                  border-radius: 5px;
                                  font-size: 10px;
                                "
                                :style="
                                  !referralGuideSelected.getCertRainForest
                                    ? 'background-color: #f3f3f3'
                                    : ''
                                "
                              >
                                <v-col
                                  cols="auto"
                                  style="
                                    padding: 0;
                                    margin: auto;
                                    text-align: center;
                                  "
                                >
                                  NO
                                </v-col>
                              </v-row>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Guía de Información -->
              <v-col cols="4" style="border-left: 1px solid black">
                <v-col cols="auto" style="margin: 0">
                  <v-row>
                    <!-- RcsNumberHarvestGuide -->
                    <v-col
                      cols="12"
                      style="
                        font-family: Arial Black, Helvetica, sans-serif;
                        font-size: 16px;
                        font-weight: bold;
                        padding: 0;
                        text-align: center;
                        color: red;
                      "
                    >
                      {{ this.referralGuideSelected.RcsNumberHarvestGuide }}
                    </v-col>
                    <!-- Fecha -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2" style="padding: 0"> Fecha: </v-col>
                        <v-col
                          cols="max"
                          style="
                            border-bottom: 1px solid black;
                            padding: 0;
                            text-align: center;
                          "
                        >
                          <span>{{
                            this.$fun.formatDateView(
                              this.referralGuideSelected.RcsDateHarvestSchedule
                            )
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- Transportista -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="auto" style="padding: 0">
                          Transportista:
                        </v-col>
                        <v-col
                          cols="max"
                          style="
                            border-bottom: 1px solid black;
                            padding: 0;
                            margin: 0;
                            height: 17px;
                            text-align: center;
                          "
                        >
                          {{ this.referralGuideSelected.GetVehicle.TptDriver }}
                        </v-col>
                        <v-col
                          cols="12"
                          style="
                            border-bottom: 1px solid black;
                            padding: 0;
                            margin: 2px;
                            height: 17px;
                            text-align: center;
                          "
                        >
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" style="margin-top: 5px">
                      <!-- Placa -->
                      <v-row>
                        <v-col cols="auto" style="padding: 0; margin-right: 5px"
                          >N. Placa:
                        </v-col>
                        <v-col cols="6">
                          <v-row>
                            <v-col
                              cols="max"
                              v-for="(item, index) in formattedEnrollment"
                              :key="index"
                              style="
                                padding: 2px;
                                margin: 0;
                                margin-right: 2px;
                                font-size: 10px;
                              "
                              :style="
                                item.value !== '-'
                                  ? 'border: 2px solid black; text-align: center;margin:0;'
                                  : 'text-align: center;margin:0;'
                              "
                            >
                              {{ item.value }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Precinto -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="auto" style="padding: 0; margin-right: 5px"
                          >N. Precinto:
                        </v-col>
                        <v-col
                          v-for="(item, index) in formattedRcsSunshineSeal"
                          :key="index"
                          style="
                            padding: 2px;
                            margin: 0;
                            margin-right: 2px;
                            with: 10px;
                          "
                          :style="
                            item.value !== '-'
                              ? 'border: 2px solid black; text-align: center;margin:0;'
                              : 'text-align: center;margin:0;'
                          "
                        >
                          {{ item.value }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>

            <!-- seccion de datos de proceso -->
            <v-row
              style="margin-top: 8px; padding: 0 8px 0 8px; font-size: 12px"
              justify="center"
            >
              <v-col cols="8">
                <v-row>
                  <v-col
                    cols="auto"
                    style="margin: 0 10px; padding: 0; text-align: center"
                  >
                    Procesadora:
                  </v-col>
                  <v-col cols="max" style="padding: 0">
                    <v-row style="margin-top: 5px">
                      <v-col
                        cols="auto"
                        v-for="(item, index) in typePlants"
                        :key="index"
                        style="
                          padding: 1px;
                          margin: 0 2px 0 2px;
                          border: 1px solid black;
                          border-radius: 5px;
                          font-size: 10px;
                          text-align: center;
                        "
                        :style="
                          item.DedDescription ==
                          referralGuideSelected.TypeProcessingPlantName
                            ? 'background-color: #f3f3f3'
                            : ''
                        "
                      >
                        {{ item.DedDescription }}
                      </v-col>
                      <v-col
                        style="
                          padding: 1px;
                          margin: 0 2px 0 2px;
                          border: 1px solid black;
                          border-radius: 5px;
                          font-size: 10px;
                        "
                      >
                        otra:
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="max">
                <v-row>
                  <v-col
                    cols="4"
                    style="margin-left: 25px; padding: 0; text-align: center"
                  >
                    Nave de:
                  </v-col>
                  <v-col cols="max" style="padding: 0">
                    <v-row style="margin-top: 5px">
                      <v-col
                        cols="auto"
                        v-for="(item, index) in typeDestinys"
                        :key="index"
                        style="
                          padding: 1px;
                          margin: 0 2px 0 2px;
                          border: 1px solid black;
                          border-radius: 5px;
                          font-size: 10px;
                          text-align: center;
                        "
                        :style="
                          item.DedValue == referralGuideSelected.TypeDestiny
                            ? 'background-color: #f3f3f3'
                            : ''
                        "
                      >
                        {{ item.DedDescription }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- seccion de producto, cuadrillas y firma -->
            <v-row style="margin: 20px">
              <v-col cols="12" style="padding: 0px">
                <v-col
                  cols="12"
                  style="width: 99% !important; text-align: center"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <v-row style="background-color: #f3f3f3">
                    <v-col
                      cols="max"
                      style="
                        font-size: 10px;
                        text-align: center;
                        width: 20%;
                        border: 1px solid black;
                      "
                    >
                      Producto
                    </v-col>
                    <v-col
                      cols="6"
                      style="
                        font-size: 10px;
                        text-align: center;
                        width: 60%;
                        border: 1px solid black;
                      "
                    >
                      Cuadrillas
                    </v-col>
                    <v-col
                      cols="3"
                      style="
                        font-size: 10px;
                        text-align: center;
                        width: 20%;
                        border: 1px solid black;
                      "
                    >
                      Firma
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="max"
                      style="
                        font-size: 10px;
                        text-align: center;
                        width: 20%;
                        border: 1px solid black;
                      "
                    >
                      <!-- mango -->
                      <v-row style="margin: 0px; padding: 0px; width: 90%">
                        <v-row style="margin: 0px; padding: 0px; width: 90%">
                          <v-col
                            cols="12"
                            style="
                              padding: 5px;
                              border: 1px solid black;
                              border-radius: 5px;
                              font-size: 10px;
                              text-align: center;
                            "
                            :style="
                              referralGuideSelected.TypeCultiveName === 'MANGO'
                                ? 'background-color: #f3f3f3'
                                : ''
                            "
                          >
                            Mango
                          </v-col>
                        </v-row>
                        <v-row
                          justify="center"
                          style="margin: 0px; padding: 0px"
                        >
                          <v-col
                            v-for="variety in [
                              'Kent',
                              'Edward',
                              'Keitt',
                              'Otra',
                            ]"
                            :key="variety"
                            cols="12"
                            style="padding: 0px; margin: 0px"
                          >
                            <v-row align="center" style="padding: 5px 0">
                              <v-col
                                cols="auto"
                                style="font-size: 10px; text-align: left"
                                >{{ variety }}</v-col
                              >
                              <v-col cols="1" style="margin: 0; padding: 0">
                                <v-checkbox
                                  dense
                                  disabled
                                  hide-details
                                  v-model="
                                    referralGuideSelected.VrtName === variety
                                  "
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-row>
                      <!-- palta -->
                      <v-row style="margin: 0px; padding: 0px; width: 90%">
                        <v-row style="margin: 0px; padding: 0px; width: 90%">
                          <v-col
                            cols="12"
                            style="
                              padding: 5px;
                              border: 1px solid black;
                              border-radius: 5px;
                              font-size: 10px;
                              text-align: center;
                            "
                            :style="
                              referralGuideSelected.TypeCultiveName === 'Palta'
                                ? 'background-color: #f3f3f3'
                                : ''
                            "
                          >
                            Palto
                          </v-col>
                        </v-row>
                        <v-row
                          justify="center"
                          style="margin: 0px; padding: 0px"
                        >
                          <v-col
                            v-for="variety in [
                              'Hass',
                              'Fuerte',
                              'Zutano',
                              'Otra',
                            ]"
                            :key="variety"
                            cols="12"
                            style="padding: 0px; margin: 0px"
                          >
                            <v-row align="center" style="padding: 5px 0">
                              <v-col
                                cols="auto"
                                style="font-size: 10px; text-align: left"
                                >{{ variety }}</v-col
                              >
                              <v-col cols="1" style="margin: 0; padding: 0">
                                <v-checkbox
                                  disabled
                                  dense
                                  hide-details
                                  v-model="
                                    referralGuideSelected.VrtName === variety
                                  "
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-row>
                      <!-- otro -->
                      <v-row style="margin: 0px; padding: 0px; width: 90%">
                        <v-row style="margin: 0px; padding: 0px; width: 90%">
                          <v-col
                            cols="12"
                            style="
                              padding: 5px;
                              border: 1px solid black;
                              border-radius: 5px;
                              font-size: 10px;
                              text-align: center;
                            "
                            :style="
                              referralGuideSelected.TypeCultiveName === 'Palta'
                                ? 'background-color: #f3f3f3'
                                : ''
                            "
                          >
                            Otro
                          </v-col>
                        </v-row>
                        <v-row style="margin: 0px; padding: 0px">
                          <v-col cols="12" style="padding: 0px; margin: 0px">
                            <v-row style="margin: 0px; padding: 0px">
                              <v-col cols="auto" style="margin: 0; padding: 0">
                                Cultivo:
                              </v-col>
                              <v-col
                                cols="max"
                                style="
                                  border-bottom: 1px solid black;
                                  padding: 0;
                                  height: 17px;
                                  text-align: center;
                                "
                              >
                              </v-col>
                            </v-row>
                            <v-row style="margin: 0px; padding: 0px">
                              <v-col cols="auto" style="margin: 0; padding: 0">
                                Variedad:
                              </v-col>
                              <v-col
                                cols="max"
                                style="
                                  border-bottom: 1px solid black;
                                  padding: 0;
                                  height: 17px;
                                  text-align: center;
                                "
                              >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-row>
                      <table style="width: 100%; margin-top: 5px">
                        <thead style="background-color: #f3f3f3">
                          <th
                            style="
                              font-size: 10px;
                              text-align: center;
                              width: 20%;
                              border: 1px solid black;
                            "
                          >
                            Observaciones
                          </th>
                        </thead>
                        <tbody>
                          <tr style="height: 150px"></tr>
                        </tbody>
                      </table>
                    </v-col>
                    <v-col
                      cols="6"
                      style="
                        font-size: 10px;
                        text-align: center;
                        width: 60%;
                        border: 1px solid black;
                      "
                    >
                      <table
                        style="
                          width: 100%;
                          border-collapse: collapse;
                          vertical-align: top;
                        "
                      >
                        <thead style="background-color: #f3f3f3">
                          <tr>
                            <th width="40%" style="border: 1px solid black">
                              Nombre Capataz
                            </th>
                            <th width="15%" style="border: 1px solid black">
                              N° Cuadrilla
                            </th>
                            <th style="border: 1px solid black">
                              N° Cosechadores
                            </th>
                            <th width="15%" style="border: 1px solid black">
                              N° Lote
                            </th>
                            <th width="15%" style="border: 1px solid black">
                              N° Jabas
                            </th>
                          </tr>
                        </thead>
                        <tbody style="font-size: 10px">
                          <tr
                            v-for="i in referralGuideSelected.GetGang"
                            :key="i.FrmID"
                          >
                            <td style="border: 1px solid black">
                              {{ i.FrmName }}
                            </td>
                            <td style="border: 1px solid black">
                              {{ i.GngNumberGang }}
                            </td>
                            <td style="border: 1px solid black">
                              {{ i.FrmQuantityHarvester }}
                            </td>
                            <td style="border: 1px solid black">
                              {{ i.FltName }}
                            </td>
                            <td style="border: 1px solid black">
                              {{ i.FrmQuantityReal }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td style="border: 1px solid black"></td>
                            <td style="border: 1px solid black"></td>
                            <td style="border: 1px solid black"></td>
                            <td style="border: 1px solid black">Total</td>
                            <td style="border: 1px solid black">
                              {{
                                referralGuideSelected.GetGang.reduce(
                                  (sum, i) => sum + i.FrmQuantityReal,
                                  0
                                )
                              }}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </v-col>
                    <!-- Firma -->
                    <v-col
                      cols="3"
                      style="
                        padding: 20px;
                        margin: 0;
                        border: 1px solid black;
                        text-align: center;
                        font-size: 10px;
                      "
                    >
                      <v-col
                        cols="12"
                        v-for="i in referralGuideSelected.GetGang"
                        :key="i.FrmID"
                        style="
                          padding: 4px;
                          height: 100px
                          font-size: 6px;
                          transform: rotate(-90deg);
                        "
                      >
                        <v-row style="margin: 0px; font-size: 12px;padding-top:20px">
                          <v-col
                            cols="6"
                            style="
                              width: 90px;
                              font-size: 8px;
                              padding-top: 2px;
                              border-top: 1px solid black;
                            "
                          >
                            {{ i.FrmName }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
            <v-row style="margin-top: 0; font-size: 12px" justify="center">
              <v-col
                cols="auto"
                style="
                  border-top: 2px solid black;
                  margin-top: 24px;
                  width: 200px;
                  text-align: center;
                  padding: 0 5px 10px 5px;
                "
              >
                PRODUCTOR
              </v-col>
              <v-col cols="3"> </v-col>
              <v-col
                cols="auto"
                style="
                  border-top: 2px solid black;
                  margin-top: 24px;
                  width: 200px;
                  text-align: center;
                  padding: 0 5px 10px 5px;
                "
              >
                SUPERVISOR
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col
                style="
                  text-align: right;
                  font-size: 10px;
                  padding-right: 30px;
                  font-weight: bolder;
                "
              >
                RECEPCIÓN
              </v-col>
            </v-row> -->
          </v-container>
        </section>
      </vue-html2pdf>
    </v-card>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
import qrCode from "vue-qr-generator";
import _sDefinition from "@/services/General/DefinitionService.js";
import _CertificationsService from "../../../services/Technicalassistance/CertificationsService.js";

export default {
  props: {
    referralGuideSelected: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHtml2pdf,
    qrCode,
  },
  data() {
    return {
      urlData: "",
      typeCrops: [],
      typeDestinys: [],
      typeCultives: [],
      typePlants: [],
    };
  },
  methods: {
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(progress) {
      this.progress = progress;
    },
    startPagination() {
      //console.log(`PDF has started pagination`);
    },

    hasPaginated() {
      //console.log(`PDF has been paginated`);
    },

    beforeDownload({ html2pdf, options, pdfContent }) {
      //console.log(`On Before PDF Generation`);
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    },
  },
  computed: {
    formattedEnrollment() {
      return formatNumberWithBoxes(
        this.referralGuideSelected.GetVehicle.TptEnrollment,
        "-",
        [3, 3]
      );
    },
    formattedProductionCode() {
      return formatNumberWithBoxes(
        this.referralGuideSelected.FltCodSenasa,
        "-",
        [3, 5, 2]
      );
    },
    formattedRcsSunshineSeal() {
      return formatNumberWithBoxes(
        this.referralGuideSelected.RcsSunshineSeal,
        "-",
        [9]
      );
    },
    formattedIdCode() {
      return formatNumberWithBoxes(this.referralGuideSelected.FltID, "-", null);
    },
  },
  created() {
    //console.log("📄 dataPdf COMPLETO:", this.referralGuideSelected);
    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: {
            defID: 1172,
            dgrID: 0,
            requestID: this.$fun.getUserID(),
          },
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.typeCrops = r.data;
        }
      });

    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: {
            defID: 1342,
            dgrID: 0,
            requestID: this.$fun.getUserID(),
          },
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.typeDestinys = r.data;
        }
      });
    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: {
            defID: 1173,
            dgrID: 0,
            requestID: this.$fun.getUserID(),
          },
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.typeCultives = r.data;
        }
      });

    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: {
            defID: 1342,
            dgrID: 0,
            requestID: this.$fun.getUserID(),
          },
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.typeDestinys = r.data;
        }
      });
    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: {
            defID: 1230,
            dgrID: 0,
            requestID: this.$fun.getUserID(),
          },
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.typePlants = r.data;
        }
      });
  },
};
function formatNumberWithBoxes(value, separator = "-", sections = null) {
  if (!value) return [];

  value = value.toString() || "";

  if (sections === null) {
    sections = [value.length];
  }

  // Eliminar cualquier espacio o separador previo
  let cleanValue = value
    .toString()
    .replace(/[^a-zA-Z0-9]/g, "") // Ahora solo elimina caracteres especiales y espacios
    .trim();

  let digits = sections.reduce((a, b) => a + b, 0);
  cleanValue = cleanValue.padStart(digits, "0");
  // Generar los cuadros con separación
  let formatted = [];
  let start = 0;

  for (let i = 0; i < sections.length; i++) {
    let segment = cleanValue.slice(start, start + sections[i]).split("");
    // Agregar cuadros de cada cifra
    formatted.push(segment.map((char) => ({ type: "number", value: char })));
    // Agregar el separador excepto al final
    if (i < sections.length - 1) {
      formatted.push([{ type: "separator", value: separator }]);
    }
    start += sections[i];
  }

  return formatted.flat();
}
</script>