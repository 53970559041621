<template>
  <v-container>
    <div>
      <s-crud
        :filter="filter"
        :config="config"
        edit
        remove
        @save="save($event)"
        @EditEvent="editEvent()"
        height="auto"
        @rowSelected="rowSelected($event)"
        search-input
        ref="gridReceptionSurveillance"
        title="Recepcion Vigilancia"
        :pdf = "selected.RcsState == 1"
        @pdf="downloadGuide()"
        no-full
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center">
              <v-col cols="6" md="6" lg="3">
                <s-date
                  label="Fecha Inicio"
                  v-model="filter.cDateInitial"
                ></s-date>
              </v-col>
              <v-col cols="6" md="6" lg="3">
                <s-date label="Fecha Fin" v-model="filter.cDateFin"></s-date>
              </v-col>

              <v-col cols="12" lg="3" md="4">
                <s-select-definition
                  v-model="filter.cDestiny"
                  :def="1175"
                  label="Destino"
                >
                </s-select-definition>
              </v-col>

              <v-col cols="12" lg="3" md="4">
                <s-select-definition
                  :def="1186"
                  label="Estado"
                  :value="2"
                  v-model="filter.cState"
                ></s-select-definition>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="3" md="4">
                <s-select-definition
                  label="Tipo de Servicio"
                  :def="1171"
                  v-model="filter.cTypeServiceID"
                ></s-select-definition>
              </v-col>

              <v-col cols="12" lg="3" md="4">
                <s-select-transport
                  full
                  clearable
                  noDefault
                  label="Placa"
                  v-model="filter.TptEnrollment"
                >
                </s-select-transport>
              </v-col>

              <v-col cols="12" lg="3" md="4">
                <s-select-foreman
                  autocomplete
                  full
                  clearable
                  label="Capataz"
                  v-model="filter.FrmID"
                ></s-select-foreman>
              </v-col>

              <v-col cols="12" md="12" lg="3">
                <s-select-type-certification
                  clearable
                  noDefault
                  label="Certificacion"
                  v-model="filter.TcrID"
                ></s-select-type-certification>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" md="4">
                <s-text
                  label="Nro. de Programación"
                  v-model="filter.HvsID"
                ></s-text>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:ViewCert="{ row }">
          <v-btn x-small color="info" block outlined @click="clickView(row)">
            <v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
          </v-btn>
        </template>

        <template v-slot:UploadFile="{ row }">
          <v-btn
            x-small
            :color="row.HvsAttach ? '#6ad898' : '#b2b2b2'"
            block
            outlined
            @click="clickUploadFile(row)"
          >
            <v-icon style="font-size: 16px !important">{{
              row.HvsAttach ? "fas fa-file-alt" : "fas fa-file"
            }}</v-icon>
          </v-btn>
        </template>

        <template scope="props">
          <alert-supplies :FltID="objLot.FltID"></alert-supplies>

          <v-card :disabled="disabled" v-if="props.item.HvsID !== 0">
            <!-- Inicio Datos de Programación -->
            <v-row>
              <div class="col-md-12 col-sm-12">
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-toolbar
                        dense
                        dark
                        :color="'#BAB6B5'"
                        style="
                          height: 28px;
                          -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%),
                            0px 0px 0px 0px rgb(0 0 0 / 4%),
                            0px 1px 0px 0px rgb(0 0 0 / 2%);
                          border-radius: 5px;
                        "
                      >
                        <v-toolbar-title style="height: 38px"
                          >Datos de Programación</v-toolbar-title
                        >
                      </v-toolbar>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p>
                        Fecha Programación:
                        {{ props.item.RcsDateHarvestSchedule }}
                      </p>
                    </v-col>
                    <v-col cols="6" md="3">
                      <p>Planta Procesadora:</p>
                    </v-col>
                    <v-col cols="6" md="3">
                      <s-select-definition
                        :def="1230"
                        v-model="props.item.TypeProcessingPlant"
                      />
                    </v-col>
                    <v-col cols="12" md="6" style="margin-top: -35px">
                      <p>
                        Nro. Programación:
                        {{ props.item.HvsID }}
                      </p>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                      style="margin-top: -30px"
                      v-if="props.item.TypeProcessingPlant == 2"
                    >
                      <p>Empacadora:</p>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                      style="margin-top: -30px"
                      v-if="props.item.TypeProcessingPlant == 2"
                    >
                      <s-select-definition
                        :def="1226"
                        v-model="props.item.TypeBaler"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-row>
            <!-- Fin Datos de Programación -->

            <!-- Inicio ==> Fundo || Vehiculos || Capataces  -->
            <v-row style="margin-top: -40px; margin-bottom: -40px">
              <!-- Inicio Fundo/User -->
              <div class="col-md-12" style="margin-right: -40px">
                <v-col cols="12" md="12">
                  <v-toolbar
                    dense
                    dark
                    :color="'#BAB6B5'"
                    style="
                      height: 28px;
                      -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%),
                        0px 0px 0px 0px rgb(0 0 0 / 4%),
                        0px 1px 0px 0px rgb(0 0 0 / 2%);
                      border-radius: 5px;
                      margin-bottom: 10px;
                    "
                  >
                    <v-toolbar-title style="height: 38px"
                      >Datos de Fundo
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-row>
                    <v-col lg="2" md="2" cols="12">
                      <s-search-lot-by-cod-senasa
                        @searchSenasa="searchSenasa($event)"
                      />
                    </v-col>
                    <v-col cols="12" lg="4" md="4">
                      <s-text
                        label="Productor"
                        v-model="objLot.PrdText"
                        readonly
                      ></s-text>
                    </v-col>

                    <v-col cols="12" lg="4" md="4">
                      <s-text
                        readonly
                        label="Fundo"
                        v-model="objLot.FagName"
                      ></s-text>
                    </v-col>

                    <v-col cols="12" lg="2" md="2">
                      <s-text
                        readonly
                        label="Lote"
                        v-model="objLot.FltName"
                      ></s-text>
                    </v-col>

                    <v-col cols="6" md="2" style="margin-top: -15px">
                      <s-select-definition
                        label="Tipo de Servicio"
                        :def="1171"
                        v-model="props.item.TypeService"
                      />
                    </v-col>

                    <v-col cols="6" md="2" style="margin-top: -15px">
                      <s-select-definition
                        :def="1174"
                        label="Procedencia"
                        v-model="props.item.TypeOrigin"
                      />
                    </v-col>

                    <v-col cols="6" md="2" style="margin-top: -15px">
                      <s-select-definition
                        :def="1172"
                        label="Tipo Cultivo"
                        v-model="objLot.TypeCrop"
                      />
                    </v-col>

                    <v-col cols="6" md="2" style="margin-top: -15px">
                      <s-select-definition
                        :def="1173"
                        label="Cultivo"
                        v-model="objLot.TypeCultive"
                        readonly
                      />
                    </v-col>

                    <v-col cols="6" md="4" style="margin-top: -15px">
                      <s-select-variety
                        :cultiveID="objLot.TypeCultive"
                        label="Variedad"
                        full
                        :text="props.item.VrtName"
                        :value="objLot.VrtID"
                        v-model="objLot.VrtID"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4" style="margin-top: -15px">
                      <s-text
                        v-model="objLot.FltCodSenasa"
                        label="Cod. Senasa"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4" style="margin-top: -15px">
                      <s-text
                        v-model="props.item.TypeDestinyName"
                        label="Destino"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4" style="margin-top: -15px">
                      <s-text
                        v-model="props.item.RcsDateReception"
                        label="Fecha Recepcion"
                        readonly
                      />
                    </v-col>

                    <v-col cols="12" md="4" style="margin-top: -15px">
                      <v-text-field
                        label="Direccion: "
                        v-model="objLot.FagAddress"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" md="4" style="margin-top: -15px">
                      <v-text-field
                        label="Zona: "
                        v-model="objLot.ZonIDText"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="4" style="margin-top: -15px">
                      <v-text-field
                        label="Sub Zona: "
                        v-model="objLot.SzoIDText"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <!-- Fin Fundo/User -->

              <!-- Inicio Vehicle Fruta-->
              <div cols="12" class="col-md-12" style="margin-top: -50px">
                <v-col cols="12" md="12">
                  <v-toolbar
                    dense
                    dark
                    :color="'#BAB6B5'"
                    style="
                      height: 28px;
                      -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%),
                        0px 0px 0px 0px rgb(0 0 0 / 4%),
                        0px 1px 0px 0px rgb(0 0 0 / 2%);
                      border-radius: 5px;
                    "
                  >
                    <v-toolbar-title style="height: 38px"
                      >Datos de Vehiculo - Fruta</v-toolbar-title
                    >
                  </v-toolbar>

                  <v-row>
                    <v-col cols="12" md="12" style="margin-top: 0px">
                      <v-data-table
                        item-key="TptId"
                        dense
                        :items="itemsVehicle"
                        :headers="headersVehicle"
                        v-model="selectedVehicle"
                        :items-per-page="-1"
                        @click:row="rowClickVehicle"
                        hide-default-footer
                      >
                        <template v-slot:item.TptEnrollment="props">
                          <s-select-transport
                            :value="props.item.TptEnrollment"
                            full
                            v-model="props.item.TptEnrollment"
                          >
                          </s-select-transport>
                        </template>

                        <template v-slot:item.TptDriver="props">
                          <s-select-driver
                            clearable
                            full
                            :value="props.item.TptDriver"
                            v-model="props.item.TptDriver"
                          >
                          </s-select-driver>
                        </template>

                        <template v-slot:item.TypeLoadName="props">
                          <s-select-definition
                            :def="1229"
                            v-model="props.item.TypeLoad"
                            disabled
                          />
                        </template>
                        <!-- 
												<template
													v-slot:item.TptNumberReferralGuide="props"
												>
													<s-text
														v-if="props.item.TypeLoad == 2"
														v-model="props.item.TptNumberReferralGuide"
														:mask="maskFormatGuideRemission"
														
													/>
												</template>

												<template v-slot:item.TptFrmName="props">
													<s-select-foreman
														v-if="props.item.TypeLoad == 2"
														clearable
														full
														:value="props.item.TptFrmID"
														v-model="props.item.TptFrmID"
													></s-select-foreman>
													
												</template> -->
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <!-- Fin Vehicle -->

              <!-- Inicio Vehicle Materiales-->
              <!-- <div
								cols="12"
								class="col-md-12"
								style="margin-top: -20px"
							>
								<v-col cols="12" md="12">
									<v-toolbar
										dense
										dark
										:color="'#BAB6B5'"
										style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
									>
										<v-toolbar-title style="height: 38px"
											>Datos de Vehiculo - Materiales</v-toolbar-title
										>
									</v-toolbar>

									<v-row>
										<v-col
											cols="12"
											md="12"
											style="margin-top: 0px"
										>
											<v-data-table
												item-key="TptId"
												dense
												:items="itemsVehicle"
												:headers="headersVehicle"
												v-model="selectedVehicle"
												:items-per-page="-1"
												@click:row="rowClickVehicle"
												hide-default-footer
											>
												<template
													v-slot:item.TptEnrollment="props"
												>
													<s-select-transport
														:value="props.item.TptEnrollment"
														full
														v-model="props.item.TptEnrollment"
													>
													</s-select-transport>
												</template>

												<template
													v-slot:item.TptDriver="
														props
													"
												>
													<s-select-driver
														clearable
														full
														:value="
															props.item.TptDriver
														"
														v-model="
															props.item.TptDriver
														"
													>
													</s-select-driver>
												</template>

												<template
													v-slot:item.TypeLoadName="
														props
													"
												>
													<s-select-definition
														:def="1229"
														v-model="
															props.item.TypeLoad
														"
													/>
												</template>
											</v-data-table>
										</v-col>
									</v-row>
								</v-col>
							</div> -->
              <!-- Fin Vehicle -->

              <!-- Inicio Capataz -->
              <!-- Fin Capataz -->

              <!-- Inicio Certificaciones -->
              <div cols="12" class="col-md-12" style="margin-top: -10px">
                <v-col cols="12" md="12" style="margin-left: 0px">
                  <v-row>
                    <v-col cols="12">
                      <s-certification-lot
                        :noClose="false"
                        noFooter
                        :height="'auto'"
                        :FltID="objLot.FltID"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-row>
            <!-- Fin ==> Fundo || Vehiculos || Capataces  -->
          </v-card>
          <div>
            <div cols="12" class="col-md-12" style="margin-top: -10px">
              <v-col cols="12" md="12" style="margin-left: 0px">
                <v-toolbar
                  dense
                  dark
                  :color="'#BAB6B5'"
                  style="
                    height: 28px;
                    -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%),
                      0px 0px 0px 0px rgb(0 0 0 / 4%),
                      0px 1px 0px 0px rgb(0 0 0 / 2%);
                    border-radius: 5px;
                    margin-bottom: 10px;
                  "
                >
                  <v-toolbar-title style="height: 38px"
                    >Capataz Programados</v-toolbar-title
                  >
                </v-toolbar>
                <!-- <v-row>

										<v-col cols="6" v-if="selectedGang.length > 0">
											<s-select-transport
												clearable
												full
												v-model="selectedGang[0].VehPlate"
												label="Placa"
											>
											</s-select-transport>
										</v-col>
										<v-col cols="6" v-if="selectedGang.length > 0" >
											<s-select-driver
												label="Chofer"
												clearable
												full
												v-model="selectedGang[0].DriverName"
											/>
										</v-col>
									</v-row> -->
                <!-- <v-row style="margin:auto">
										<v-col cols="3" lg="3" class="s-col-form" v-if="objTransport">
												<s-select-farm
										@dblclick="dblclickTraspot($event)"
										:typeCharge="1"
										Quantity="0"
										:label="'Chofer - Materiales'"
										/>
										</v-col>
										<v-col cols="6" lg="6" class="s-col-form" v-if="objTransport">
											<s-text
												label="Conductor - Materiales"
												:value="objTransport.VehPlate+' '+objTransport.Driver"
												readonly
											/>
										</v-col>
										<span ><v-btn elevation="0" class="mt-4" color="error" fab x-small v-if="objTransport" @click="deleteTransportCap()">X</v-btn></span>
									</v-row> -->
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      item-key="GngID"
                      dense
                      :items="itemsGang"
                      :headers="headersGang"
                      v-model="selectedGang"
                      :items-per-page="-1"
                      @click:row="rowClickGang"
                      hide-default-footer
                    >
                      <template v-slot:item.FrmQuantityReal="props">
                        <s-text
                          v-model="props.item.FrmQuantityReal"
                          number
                          :min="0"
                          :disabled="disabled"
                        ></s-text>
                      </template>
                      <template v-slot:item.FrmQuantityHarvester="props">
                        <s-text
                          v-model="props.item.FrmQuantityHarvester"
                          number
                          :disabled="disabled"
                        ></s-text>
                      </template>
                      <template v-slot:item.FrmName="props">
                        <s-select-foreman
                          clearable
                          full
                          :value="props.item.FrmID"
                          v-model="props.item.FrmID"
                          :disabled="disabled"
                        ></s-select-foreman>
                      </template>
                      <template v-slot:item.FltName="props">
                        <s-select-lot
                          v-if="objLot.FltID"
                          :fkFltID="objLot.FltID"
                          :PrdID="objLot.PrdID"
                          v-model="props.item.FltID"
                          clearable
                          full
                          :disabled="disabled"
                        />
                      </template>
                      <template v-slot:item.VehPlate="props">
                        <s-select-transport
                          clearable
                          :value="props.item.VehPlate"
                          full
                          v-model="props.item.VehPlate"
                          :disabled="disabled"
                        >
                        </s-select-transport>
                      </template>
                      <template v-slot:item.DriverName="props">
                        <s-select-driver
                          clearable
                          full
                          :value="props.item.DriverName"
                          v-model="props.item.DriverName"
                          :disabled="disabled"
                        >
                        </s-select-driver>
                      </template>

                      <template v-slot:item.TasGngNumberGuideRemitente="props">
                        <s-text
                          v-if="props.item.VehPlate.length > 0"
                          v-model="props.item.TasGngNumberGuideRemitente"
                          :mask="maskFormatGuideRemission"
                          :disabled="disabled"
                        >
                        </s-text>
                      </template>
                      <template v-slot:item.TasGngNumberGuideMaterials="props">
                        <s-text
                          v-if="props.item.VehPlate.length > 0"
                          v-model="props.item.TasGngNumberGuideMaterials"
                          :mask="maskFormatGuideRemission"
                        >
                        </s-text>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </div>
          <!--Inicio Documentos-->
          <v-row>
            <div
              class="col-md-12 col-sm-12"
              style="margin-top: 30px"
              v-if="props.item.HvsID !== 0"
            >
              <v-col cols="12" md="12" style="margin-left: 0px">
                <v-card flat outlined>
                  <v-card-text>
                    <b>Documentos || </b>
                    <span
                      style="
                        margin-left: 2%;
                        color: red;
                        font-size: 11px;
                        position: absolute;
                      "
                    >
                      <b>Campos Obligatorios (*)</b>
                    </span>
                  </v-card-text>

                  <v-card-actions>
                    <v-row>
                      <v-col cols="6" md="3" style="margin-top: 0px">
                        <s-text
                          label="Nro. Guia de Cosecha"
                          type="text"
                          v-model="props.item.RcsNumberHarvestGuide"
                          :mask="maskFormatGuideHarvested"
                          :min="3"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col cols="6" md="3" style="margin-top: 0px">
                        <s-text
                          label="Nro. Guia de Remision"
                          type="text"
                          v-model="props.item.RcsNumberReferralGuide"
                          :mask="maskFormatGuideRemission"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col cols="6" md="6" style="margin-top: 0px">
                        <s-text
                          label="Precinto Sunshine"
                          type="text"
                          v-model="props.item.RcsSunshineSeal"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col cols="6" md="3" style="margin-top: -10px">
                        <s-text
                          label="Precinto Senasa"
                          type="text"
                          v-model="props.item.RcsSenasaSeal"
                        />
                      </v-col>

                      <v-col cols="6" md="3" style="margin-top: -10px">
                        <!-- :mask="'AA##-###############'" -->
                        <s-text
                          label="N.G.R Transportista"
                          type="text"
                          v-model="props.item.RcsNumberFerralGuideDriver"
                        >
                          <template v-slot:append>
                            <i
                              style="color: red; font-size: 9px"
                              class="fa fa-asterisk"
                            ></i>
                          </template>
                        </s-text>
                      </v-col>

                      <v-col cols="6" md="3" style="margin-top: -10px">
                        <s-text
                          label="N.G.R-Materiales"
                          type="text"
                          v-model="props.item.RcsNumberFerralGuideMaterials"
                          :mask="'A###-###############'"
                        />
                      </v-col>

                      <v-col cols="12" lg="3" md="3" style="margin-top: -10px">
                        <s-text
                          label="Guía de remisión Transportista-Materiales"
                          type="text"
                          v-model="
                            props.item.RcsNumberFerralGuideDriverMaterials
                          "
                        />
                      </v-col>
                      <!-- :mask="'AA##-###############'" -->
                      <v-col cols="12" md="12" style="margin-top: 0px">
                        <s-textarea
                          style="margin-top: -15px"
                          v-model="props.item.RcsObservations"
                          label="Observaciones"
                        ></s-textarea>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </div>
          </v-row>
          <!--Fin Documentos-->
        </template>
      </s-crud>
      <v-dialog
        v-if="dialogGuidePdf"
        v-model="dialogGuidePdf"
        fullscreen
        persistent
      >
        <guide-pdf
          :referralGuideSelected="dataPdf"
          @close="dialogGuidePdf = false"
        ></guide-pdf>
      </v-dialog>
      <v-dialog
        v-if="openDialogView"
        v-model="openDialogView"
        persistent
        style="display: contents"
        width="70%"
      >
        <s-certification-lot :FltID="FltID" @close="close()" />
      </v-dialog>

      <v-dialog
        v-if="openDialogUploadFile"
        v-model="openDialogUploadFile"
        persistent
        style="display: contents"
        width="70%"
        max-width="600px"
      >
        <template>
          <!-- <div class="col-md-12 col-sm-12"> -->

          <v-card style="height: 17vh; max-height: 20vh">
            <v-col cols="12" md="12" style="margin-left: 0px; padding: unset">
              <s-toolbar
                label="Subir Adjunto"
                :color="'#BAB6B5'"
                dark
                :save="true"
                :close="true"
                @close="close()"
                @save="saveFile($event)"
              />
            </v-col>

            <v-row style="margin: auto">
              <v-col lg="12" cols="12" class="s-col-text">
                <v-row>
                  <v-col lg="10"
                    ><v-file-input label="Anexo" v-model="Attach" hide-details
                  /></v-col>
                  <v-col
                    ><v-btn
                      color="success"
                      class="mt-5"
                      :disabled="false"
                      icon
                      x-small
                      @click="openFile(Attach)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn></v-col
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <!-- </div> -->
        </template>
      </v-dialog>

      <v-dialog
        v-model="processing"
        v-if="processing"
        :persistent="messageProcessing.length == 0"
        width="400"
      >
        <v-card color="primary" dark>
          <v-card-text v-if="messageProcessing.length == 0">
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-text v-else>
            <div class="text--white pt-4">
              Error al realizar operacion <br />
              {{ messageProcessing }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
//Service
import _sReceptionSurveillance from "../../../services/Technicalassistance/ReceptionSurveillanceService";
import _sGenParam from "../../../services/General/ParameterService";
//Component
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import SSelectLot from "@/components/HarvestSchedule/SSelectLot.vue";
import SSelectFarm from "@/components/HarvestSchedule/SSelectFarm";
import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";
import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import SSelectForeman from "@/components/HarvestSchedule/SSelectForeman";
import SSelectDriver from "@/components/HarvestSchedule/sSelectDriver.vue";
import AlertSupplies from "@/components/FreshProduction/FreshReception/AlertSupplies.vue";
import sSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";
import s_HarvestSchedule from "@/services/Technicalassistance/HarvestSchedule.js";
import _sGeneral from "@/services/HelperService.js";
import _constapp from "@/plugins/const";
import GuidePdf from "./ReceptionSurveillancePdf.vue";

import _CertificationsService from "../../../services/Technicalassistance/CertificationsService.js";
import _sHarvestSchedule from "../../../services/Technicalassistance/HarvestSchedule.js";
export default {
  components: {
    SSelectLot,
    SSelectTransport,
    SSelectVariety,
    SSelectForeman,
    SSelectDriver,
    SCertificationLot,
    AlertSupplies,
    SSearchLotByCodSenasa,
    sSelectTypeCertification,
    SSelectFarm,
    GuidePdf,
  },
  data: () => ({
    disabled: false,
    selectedGang: [],
    selectedVehicle: [],
    itemsGang: [],
    itemsVehicle: [],
    headersGang: [
      { text: "Placa", value: "VehPlate", width: 150 },
      { text: "Chofer", value: "DriverName", width: 200 },
      { text: "SubLote", value: "FltName", width: 200 },
      { text: "Capataz", value: "FrmName", width: 200 },
      {
        text: "Cant. Personal.",
        value: "FrmQuantityHarvester",
        width: 100,
      },
      {
        text: "Cant. Jabas Prog.",
        value: "FrmQuantityEstimate",
        width: 100,
      },
      {
        text: "Cant. Jabas Cosechadas",
        value: "FrmQuantityReal",
        width: 100,
      },

      {
        text: "Guia Remitente",
        value: "TasGngNumberGuideRemitente",
        width: 100,
      },
      {
        text: "Guia Remisión Mat.",
        value: "TasGngNumberGuideMaterials",
        width: 100,
      },
    ],

    headersVehicle: [
      { text: "Placa", value: "TptEnrollment", width: 150 },
      { text: "Chofer", value: "TptDriver", width: 200 },
      { text: "Tipo de Carga", value: "TypeLoadName", width: 100 },
      /* { text: "Capataz", value: "TptFrmName", width: 100 },
				{ text: "Guia Remision", value: "TptNumberReferralGuide", width: 100 } */
    ],

    filter: {
      cDateInitial: null,
      cDateFin: null,
      /* cTypeServiceID: 1,
					
					cDestiny: 1,
					
					TcrID: null, */
      filterAll: 0,
      cState: 2,
      HvsID: null,
    },

    config: {
      service: _sReceptionSurveillance,
      model: {
        HvsID: "ID",
        RcsDateHarvestSchedule: "date",
        RcsDateReception: "date",
        ViewCert: "",
        UploadFile: "",
      },
      headers: [
        { text: "View", value: "ViewCert", width: 80 },
        { text: "Adjunto", value: "UploadFile", width: 80 },
        { text: "Nro. Prog.", value: "HvsID", width: 100 },
        { text: "Fec. Prog.", value: "RcsDateHarvestSchedule", width: 140 },
        { text: "Productor", value: "PrdCardName", width: 300 },
        { text: "Fundo", value: "FagName", width: 300 },
        { text: "Lote", value: "FltName", width: 100 },
        { text: "Cultivo", value: "TypeCultiveName", width: 100 },
        { text: "Variedad", value: "VrtName", width: 100 },
        { text: "Fec. Recepcion", value: "RcsDateReception", width: 140 },
        { text: "Cod. Senasa", value: "FltCodSenasa", width: 150 },
        { text: "Guia Cosecha.", value: "RcsNumberHarvestGuide", width: 140 },
        { text: "Guia Remision.", value: "RcsNumberReferralGuide", width: 140 },
        { text: "Prec. Senasa", value: "RcsSenasaSeal", width: 140 },
        { text: "Pre. Sunshine", value: "RcsSunshineSeal", width: 340 },
        { text: "Observaciones", value: "RcsObservations", width: 140 },
        /* { text: "Estado",               value: "RcsState", width: 30 }, */
      ],
    },

    selected: {},
    dialogGuidePdf: false,
    openDialogView: false,
    openDialogUploadFile: false,
    Attach: null,
    //listSupplies: [],
    //hasSuppliesNotRecommended: false,
    existsData: false,

    //Obj Lot
    objLot: { PrdText: null, FagName: null, FltName: null },
    FltID: 0,

    maskFormatGuideHarvested: "",
    maskFormatGuideRemission: "",
    xCount: 0,
    objTransport: null,
    alertCertification: false,

    processing: false,
    error: false,
    messageProcessing: "",
    isLoading: false,
  }),
  created() {
    this.loadParameter();
  },
  watch: {},

  methods: {
    /* dblclickTraspot({item}){
				this.objTransport=item;
				if(this.selectedGang.length > 0){
					this.selectedGang[0].VehPlate = item.VehPlate;
					this.selectedGang[0].DriverName = item.Driver;
					this.selectedGang[0].NtpIDDriver = item.NtpIDDriver;
					console.log(item);
				}
				
			}, */
    downloadGuide() {
      this.dataPdf = {
        ...this.selected,
        GetGang: [],
        GetVehicle: [],
        getCertGlobal: false,
        GetCertRainForest: false,
      };
      (this.isLoading = false),
        Promise.all([
          _sReceptionSurveillance
            .GetGang({ HvsID: this.selected.HvsID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsGang = r.data;
              this.dataPdf.GetGang = this.itemsGang;
            }),

          _sReceptionSurveillance
            .GetTransport({ HvsID: this.selected.HvsID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsVehicle = r.data;
              this.dataPdf.GetVehicle = this.itemsVehicle.length
                ? this.itemsVehicle[0]
                : {};
            }),
          _sReceptionSurveillance
            .getCertGlobal(this.selected.FltID, this.$fun.getUserID())
            .then((r) => {
              //console.log("📄 getCertGlobal", r.data);
              this.dataPdf.getCertGlobal = r.data;
            }),

          _sReceptionSurveillance
            .getCertRainForest(this.selected.FltID, this.$fun.getUserID())
            .then((r) => {
              //console.log("📄 getCertRainForest", r.data);
              this.dataPdf.getCertRainForest = r.data;
            }),
        ])
          .then(() => {
            this.dialogGuidePdf = true; // 🔥 Solo abrimos el modal cuando todo esté listo
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    loadParameter() {
      _sGenParam
        .search({ PrmName: "maskFormatGuideHarvested" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.maskFormatGuideHarvested = resp.data.PrmValue;
          }
        });

      _sGenParam
        .search({ PrmName: "maskFormatGuideRemission" }, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.maskFormatGuideRemission = resp.data.PrmValue;
          }
        });
    },
    rowClickGang: function (item, row) {
      this.selectedGang = [item];
      //this.objDriver.DriverName = item.DriverName;
      this.objTransport = {
        NtpIDID: item.NtpIDDriver,
        Driver: item.DriverName,
        VehPlate: item.VehPlate,
      };
      //console.log("this.selectedGang",this.objDriver);
    },

    rowClickVehicle: function (item, row) {
      this.selectedVehicle = [item];
    },

    clickView(val) {
      this.FltID = val.FltID;
      this.openDialogView = true;
    },

    clickUploadFile(val) {
      this.FltID = val.FltID;
      this.openDialogUploadFile = true;
    },

    close() {
      this.openDialogView = false;
      this.openDialogUploadFile = false;
      this.Attach = null;
    },

    rowSelected(item) {
      if (item.length == 0) {
        this.selected = { SecStatus: 1 };
        this.objLot = {};
        this.selected.RcsState = 0;
        this.itemsGang = [];
        this.itemsVehicle = [];
        this.existsData = false;
        this.disabled = true;
        this.objTransport = null;
        //this.hasSuppliesNotRecommended = false;
      } else {
        if (item[0] != undefined) {
          this.selected = item[0];
          this.existsData = true;

          if (this.selected.HvsAttach != null) {
            this.Attach = { name: this.selected.HvsAttach };
          }
          _sReceptionSurveillance
            .GetGang({ HvsID: this.selected.HvsID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsGang = r.data;
              //console.log("itemsGang", this.itemsGang);
            });

          _sReceptionSurveillance
            .GetTransport({ HvsID: this.selected.HvsID }, this.$fun.getUserID())
            .then((r) => {
              this.itemsVehicle = r.data;
              //console.log("this.itemsVehicle", this.itemsVehicle);
            });
          this.dataPdf = this.selected;
          //console.log("itemsGang", this.itemsGang);
          //console.log("itemsVehicle", this.itemsVehicle);

          this.dataPdf.GetGang = this.itemsGang[0];
          this.dataPdf.GetTransport = this.itemsVehicle[0];
          //console.log("dataPdf", this.dataPdf);

          this.objLot.PrdText = this.selected.PrdCardName;
          this.objLot.FagName = this.selected.FagName;
          this.objLot.FltName = this.selected.FltName;

          this.objLot.PrdID = this.selected.PrdID;
          this.objLot.FagID = this.selected.FagID;
          this.objLot.FltID = this.selected.FltID;
          this.objLot.FltCodSenasa = this.selected.FltCodSenasa;

          this.objLot.TypeCrop = this.selected.TypeCrop;
          this.objLot.TypeCultive = this.selected.TypeCultive;
          this.objLot.VrtID = this.selected.VrtID;

          this.objLot.FagAddress = this.selected.FagAddres;
          this.objLot.ZonIDText = this.selected.ZonName;
          this.objLot.SzoIDText = this.selected.SzoName;

          if (this.selected.RcsState == 1) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
        }
      }
    },

    isValidatedForeman() {
      let isValidated = true;
      //Validar Grilla Capataces
      if (this.itemsGang.length == 0) {
        this.$fun.alert(
          "La Programacion: " +
            this.selected.HvsID +
            ", No cuenta con Capataces asignados.",
          "warning"
        );
        isValidated = false;
        return;
      }

      this.itemsGang.forEach((element) => {
        if (element.FrmID == null) {
          this.$fun.alert(
            "Uno de los registro no se ha cargado Capataz",
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FltID == null) {
          this.$fun.alert(
            "Uno de los registro no se ha cargado Sub-Lote",
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FrmQuantityHarvester <= 0) {
          this.$fun.alert(
            "Cantidad de Cosechadores no puede ser menor o igual a 0, para Capataz: " +
              element.FrmName,
            "warning"
          );
          isValidated = false;
          return;
        }

        if (element.FrmQuantityReal <= 0) {
          this.$fun.alert(
            "Cantidad de Fruta no puede ser menor o igual a 0, para Capataz: " +
              element.FrmName,
            "warning"
          );
          isValidated = false;
          return;
        }
      });

      return isValidated;
    },

    isValidatedVehicle() {
      //Validar Grilla Vehiculos
      let isValidated = true;
      if (this.itemsVehicle.length == 0) {
        this.$fun.alert(
          "La Programacion: " +
            this.selected.HvsID +
            ", No cuenta con Vehiculos asignados.",
          "warning"
        );
        isValidated = false;
        return;
      }

      this.itemsVehicle.forEach((element) => {
        if (element.TptEnrollment == null) {
          this.$fun.alert("Necesita especificar un Vehiculo", "warning");
          isValidated = false;
          return;
        }

        if (element.TptDriver == null) {
          this.$fun.alert("Necesita especificar un Chofer", "warning");
          isValidated = false;
          return;
        }

        if (element.TypeLoad == null) {
          this.$fun.alert("Necesita especificar tipo de carga", "warning");
          isValidated = false;
          return;
        }

        /* if ((element.TptNumberReferralGuide.length == 0 || element.TptNumberReferralGuide == null) && element.TypeLoad == 2) {
						this.$fun.alert(
							"Especifique Guia de Remision para Vehiculos de Materiales de Capataz: " +
								element.TptFrmName,
							"warning"
						);
						isValidated = false;
						return;
					} */
      });

      return isValidated;
    },

    isValidatedDocuments(val) {
      let isValidated = true;
      if (
        val.RcsNumberHarvestGuide == "" ||
        val.RcsNumberHarvestGuide == null
      ) {
        this.$fun.alert("Ingrese Numero Guia de Cosecha", "warning");
        isValidated = false;
        return;
      }

      if (
        val.RcsNumberReferralGuide == "" ||
        val.RcsNumberReferralGuide == null
      ) {
        this.$fun.alert("Ingrese numero Guia de Remision", "warning");
        isValidated = false;
        return;
      }

      if (
        val.RcsNumberFerralGuideDriver == "" ||
        val.RcsNumberFerralGuideDriver == null
      ) {
        this.$fun.alert(
          "Ingrese numero Guia remision de Transportista",
          "warning"
        );
        isValidated = false;
        return;
      }

      if (val.RcsSunshineSeal == "" || val.RcsSunshineSeal == null) {
        this.$fun.alert("Ingrese Precinto Sunshine", "warning");
        isValidated = false;
        return;
      }

      return isValidated;
    },

    isValidatedHeader(val) {
      let isValidated = true;

      if (val.HvsID == 0) {
        this.$fun.alert("No se ha seleccionado registro", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.PrdText == null || this.objLot.PrdText == undefined) {
        this.$fun.alert("No se ha seleccionado Productor", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.FagName == null || this.objLot.FagName == undefined) {
        this.$fun.alert("No se ha seleccionado Fundo", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.FltName == null || this.objLot.FltName == undefined) {
        this.$fun.alert("No se ha seleccionado Lote", "warning");
        isValidated = false;
        return;
      }

      if (
        this.objLot.TypeCultive == undefined ||
        this.objLot.TypeCultive == null
      ) {
        this.$fun.alert("No se ha seleccionado Cultivo", "warning");
        isValidated = false;
        return;
      }

      if (this.objLot.TypeCrop == undefined || this.objLot.TypeCrop == null) {
        this.$fun.alert("No se ha seleccionado Tipo Cultivo", "warning");
        isValidated = false;
        return;
      }

      return isValidated;
    },

    save(val) {
      if (!this.isValidatedHeader(val)) {
        return;
      }
      if (!this.isValidatedForeman()) {
        return;
      }
      if (!this.isValidatedVehicle()) {
        return;
      }
      if (!this.isValidatedDocuments(val)) {
        return;
      }

      this.xCount = 0;
      val.SecStatus = 1;
      val.UsrCreateID = this.$fun.getUserID(); //Obtenemos el ID del Usuario
      val.RcsState = 1;
      val.RcsDateReception = this.$fun.getDate(0);

      val.PrdID = this.objLot.PrdID;
      val.FagID = this.objLot.FagID;
      val.FltID = this.objLot.FltID;

      val.TypeCrop = this.objLot.TypeCrop;
      val.TypeCultive = this.objLot.TypeCultive;
      val.VrtID = this.objLot.VrtID;

      //Verificamos que Planta Procesadora es Sunshine, Establecemos Empacadora en NULL
      if (val.TypeProcessingPlant == 1) {
        val.TypeBaler = null;
      }

      //Agregamos el RcsID a Capataces
      this.itemsGang.forEach((element) => {
        element.RcsID = val.RcsID;
        element.SecStatus = 1;
        element.UsrCreateID = this.$fun.getUserID();
      });

      //Agregamos el RcsID a Vehiculos
      this.itemsVehicle.forEach((element) => {
        element.RcsID = val.RcsID;
        element.SecStatus = 1;
        element.UsrCreateID = this.$fun.getUserID();

        if (element.TypeLoad == 1) {
          this.xCount = this.xCount + 1;
        }
      });

      val.itemsGang = this.itemsGang;
      val.itemsVehicle = this.itemsVehicle;

      if (this.xCount > 1) {
        this.$fun.alert(
          "Error... La Programacion de Cosecha solo puede tener 01 vehiculo para transporte de Fruta",
          "warning"
        );
        return;
      }

      if (this.xCount == 0) {
        this.$fun.alert(
          "Error... La Programacion de Cosecha no contiene vehiculo para transporte de Fruta",
          "warning"
        );
        return;
      }

      //console.log("Guardar", val);

      this.$fun.alert("Seguro de Guardar?", "question").then((r) => {
        if (r.value) {
          this.processing = true;
          _sReceptionSurveillance.save(val, this.$fun.getUserID()).then(
            (resp) => {
              if (resp.status == 200) {
                this.processing = false;
                this.$fun.alert("Guardado Correctamente", "success");
                this.$refs.gridReceptionSurveillance.refresh();
              }
            },
            (e) => {
              this.error = true;
              this.messageProcessing = e.response.data.Message;
              this.processing = true;
            }
          );
        }
      });

      //val.save();
    },

    editEvent() {
      if (this.existsData == false) {
        this.$fun.alert("No se ha seleccionado registro", "warning");
      }
    },

    searchSenasa(item) {
      //console.log("datos de busqueda", item);
      if (item) this.objLot = item;
      else this.objLot = {};
    },
    /* deleteTransportCap(){
				this.objTransport.NtpIDID = null 
				this.objTransport.Driver = null 
				this.objTransport.VehPlate = null

				this.selectedGang[0].VehPlate = null
				this.selectedGang[0].DriverName = null
				this.selectedGang[0].NtpIDDriver = null

			} */

    uploadFile() {
      var formData = new FormData();
      let i = 0;

      formData.append(i.toString(), this.Attach);
      i++;

      _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3);
      this.$fun.alert("Guardado Correctamente", "success");
      this.$refs.gridReceptionSurveillance.refresh();
      this.$emit("onClose");
    },

    openFile(url) {
      //console.log("Nombre de url", url);
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        //console.log("rtpa", r);
        this.$fun.download(r.data, url.name);
        // if(r.status === 200){

        // 	this.$fun.downloadHttp(r);
        // }
      });
    },

    saveFile() {
      //console.log("Nombre del Archivo: ", this.Attach);
      //console.log("Item:", this.selected);
      s_HarvestSchedule
        .uploadFileName(
          this.selected.HvsID,
          this.Attach.name,
          this.$fun.getUserID()
        )
        .then((r) => {
          if (r.status === 200) {
            console.log("Subido Correcto");
            this.uploadFile();
          }
        });
    },
  },
};
</script>
